<template>
  <top-bar-dropdown ref="mobileTopBarDropdown">
    <template v-for="step in financialPlanNavigationsSteps">
      <top-bar-dropdown-item
        v-if="step.visibleMobile"
        :key="step.name"
        :item="step"
        @click="onItemClick(step)"
      >
        <template #icon>
          <i
            :class="step.icon"
            class="ib-icon"
          />
        </template>
        <template v-if="step.children">
          <top-bar-dropdown-sub-item
            v-for="children in step.children"
            :key="children.name"
            :active="$route.name === children.route"
            :disabled="!children.route"
            @click="onSubItemClick(children)"
          >
            <template #icon>
              <i :class="children.icon" class="ib-icon h4 mr-2" />
            </template>
            {{ $t(children.name) }}
            <template v-if="subscriptionIsFreeTrial && children.paid">
              <i class="ib-icon ib-icon-lock-2 ml-1 o-50" />
            </template>
          </top-bar-dropdown-sub-item>
        </template>
      </top-bar-dropdown-item>
    </template>
  </top-bar-dropdown>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import TopBarDropdown from '@/components/_v2/TopBar/TopBarDropdown'
import TopBarDropdownItem from '@/components/_v2/TopBar/TopBarDropdownItem'
import TopBarDropdownSubItem from '@/components/_v2/TopBar/TopBarDropdownSubItem'

import { steps } from '@/data/financialPlanNavigationsSteps'

import { mapMutations } from 'vuex'

export default {
  name: 'FinancialPlanMobileDropdown',

  components: {
    TopBarDropdown,
    TopBarDropdownItem,
    TopBarDropdownSubItem
  },

  mixins: [MixinUserSubscription],

  data () {
    return {
      financialPlanNavigationsSteps: steps
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    onItemClick (item) {
      if (item.children !== null) return
      if (this.$router.currentRoute.name !== item.route) {
        this.$router.push({ name: item.route })
      }
      this.$refs.mobileTopBarDropdown.close()
    },

    onSubItemClick (children) {
      if (this.subscriptionIsFreeTrial && children.paid) {
        this.setDialogUpgradeState(true)
        this.$refs.mobileTopBarDropdown.close()

        return
      }

      if (this.$router.currentRoute.name !== children.route) {
        this.$router.push({ name: children.route })
      }
      this.$refs.mobileTopBarDropdown.close()
    }
  }
}
</script>
